import React from "react";
import { useEffect, useState } from "react";

import config from "./config";
import Update from "./Update/Update";
import UpdatePPT from "./Update/UpdatePPT";
import axios from "axios";
import "./App.css";
import NavBar from "./NavBar/NavBar";
import SideBar from "./SideBar/SideBar";

import {
  hasFiltersApplied,
  isKeyChecked,
  isSearchFilterIncluded,
} from "./searchHelper";
import Footer from "./Footer/Footer";

function App() {
  const { UpdatesDatabaseEndpoint } = config;

  const [updates, setUpdates] = useState([]);
  const [filteredUpdates, setFilteredUpdates] = useState([]);
  const [daysAgo, setDaysAgo] = useState(30);
  const [onlyNew, setOnlyNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [loadTime, setLoadTime] = useState(0);
  const [errors, setErrors] = useState([]);

  const [oldUI, setOldUI] = useState(false);

  // Filters
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredStatus, setFilteredStatus] = useState({});
  const [filteredServices, setFilteredServices] = useState({});

  const setError = (error) => {
    setErrors([...errors, new Date().toLocaleTimeString() + ": " + error]);
  };

  const fetchItems = async () => {
    setLoading(true);
    const startTime = Date.now();
    await axios
      .get(
        `${UpdatesDatabaseEndpoint}?onlyNew=${JSON.stringify(
          onlyNew
        )}&daysAgo=${daysAgo}`
      )
      .then(({ data: updates }) => {
        const servicesSet = new Set(updates.map((update) => update.service));
        const servicesArr = Array.from(servicesSet).map((service) => {
          return {
            name: service,
            displayName: service.startsWith("Azure")
              ? service.substr(6)
              : service,
          };
        });

        setUpdates(updates);
        servicesArr.sort((a, b) => {
          if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
            return -1;
          else return 1;
        });
        setServices(servicesArr);
        setLoadTime(Date.now() - startTime);
        setLoading(false);
      })
      .catch((e) => {
        const errorMessage = `Error fetching updates on ${UpdatesDatabaseEndpoint}: ${e.message}`;
        setError(errorMessage);
        console.log(e);
      });
  };

  useEffect(() => {
    user && fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function getUserInfo() {
    const response = await fetch("/.auth/me");
    const payload = await response.json();
    const { clientPrincipal } = payload;
    setUser(clientPrincipal);
  }

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFilter = (update) => {
    if (hasFiltersApplied(searchFilter, filteredStatus, filteredServices)) {
      if (!isKeyChecked(update.status, filteredStatus)) return false;
      if (!isKeyChecked(update.service, filteredServices)) return false;
      if (!isSearchFilterIncluded(update, searchFilter)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (!hasFiltersApplied(searchFilter, filteredStatus, filteredServices)) {
      setFilteredUpdates(updates);
      return;
    }

    const fUpdates = updates.filter(updateFilter);
    setFilteredUpdates(fUpdates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates, searchFilter, filteredStatus, filteredServices]);

  return (
    <div>
      <NavBar user={user} oldUI={oldUI} setOldUI={setOldUI} />
      {!user && (
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 align-self-center mt-4">
            <div className="alert alert-danger" role="alert">
              <strong>Ooops!</strong> You need to login to see the updates
            </div>
          </div>
        </div>
      )}
      {user && (
        <div className="d-flex">
          <SideBar
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            filteredStatus={filteredStatus}
            setFilteredStatus={setFilteredStatus}
            filteredServices={filteredServices}
            setFilteredServices={setFilteredServices}
            services={services}
          />

          <div className="d-flex flex-column">
            <div className={"row m-1 mt-3"}>
              <div className={"col-auto"}>
                <div className="input-group">
                  <div className="input-group-text">Days ago:</div>
                  <input
                    type="number"
                    className="form-control"
                    value={daysAgo}
                    onChange={(e) => setDaysAgo(parseInt(e.target.value))}
                    style={{ width: 72 }}
                  />
                </div>
              </div>
              <div className={"col-auto"}>
                <div className="form-check form-switch mt-2">
                  <label
                    className="form-check-label"
                    htmlFor={"only-new"}
                    style={{ cursor: "pointer" }}
                  >
                    Only new
                  </label>

                  <input
                    className="form-check-input"
                    type={"checkbox"}
                    role={"switch"}
                    checked={onlyNew}
                    onChange={(e) => setOnlyNew(e.target.checked)}
                    id={"only-new"}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={fetchItems}
                  style={{ width: 120 }}
                >
                  {loading && (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp;&nbsp;
                    </>
                  )}

                  {loading ? "Loading" : "Search"}
                </button>
              </div>
              <div className="col-auto mt-2">
                <span>{filteredUpdates.length} results</span>
              </div>

              {filteredUpdates.length > 0 && (
                <div className="col-auto ms-auto">
                  <button
                    type="button"
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => {
                      axios
                        .post("/api/mark-all-as-checked-cosmos")
                        .then(() => {
                          fetchItems();
                        })
                        .catch((e) => {
                          const errorMessage = `Error marking all as checked on /api/mark-all-as-checked-cosmos: ${e.message}`;
                          setError(errorMessage);
                          console.log(e);
                        });
                    }}
                  >
                    Mark all as read
                  </button>
                </div>
              )}
              <div className="row" style={{ width: "calc(100vw - 300px)" }}>
                {filteredUpdates.map((update) => {
                  return oldUI ? (
                    <Update
                      update={update}
                      key={update.link}
                      triggerReload={fetchItems}
                      setError={setError}
                    />
                  ) : (
                    <UpdatePPT
                      update={update}
                      key={update.link}
                      triggerReload={fetchItems}
                      setError={setError}
                    />
                  );
                })}
              </div>
              {filteredUpdates.length === 0 && (
                <div className="">
                  <div className="mt-4 alert alert-primary" role="alert">
                    <strong>All done!</strong> It looks like all updates are
                    already checked.
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer
            amountUpdates={filteredUpdates.length}
            loadTime={loadTime}
            onlyNew={onlyNew}
            daysAgo={daysAgo}
            amountServices={services.length}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      )}
    </div>
  );
}

export default App;
