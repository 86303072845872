import React from "react";
import { useState } from "react";
import axios from "axios";
import {
  GetImagesEndpoint,
  UpdatesScraperEndpoint,
  DeleteImageEndpoint,
} from "../config";

export default function ImageList({ leftColumnRef, update, setError }) {
  const [images, setImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const loadImages = () => {
    setShowLoading(true);
    axios
      .get(`${GetImagesEndpoint}?id=${update.id}`)
      .then(({ data }) => {
        setImages(data);
        setShowImages(true);
        setImagesLoaded(true);
        setShowLoading(false);
      })
      .catch((e) => {
        console.log(e);
        const errorMessage = `Error loading images on ${GetImagesEndpoint}: ${e.message}`;
        setError(errorMessage);
        setShowLoading(false);
      });
  };

  const scrapeImages = () => {
    axios
      .post(`${UpdatesScraperEndpoint}`, {
        date: update.date,
        url: update.link,
      })
      .then(({ data }) => {
        setImagesLoaded(false);
      });
  };

  const deleteImage = (image) => {
    axios
      .post(`${DeleteImageEndpoint}`, { imageUrl: image, updateId: update.id })
      .then(() => {
        loadImages();
      });
  };

  return (
    <div
      className="col-12 col-lg-4 col-md-6"
      style={{
        minHeight: Math.max(400, leftColumnRef.current?.clientHeight || 0),
        height: leftColumnRef.current?.clientHeight || 0,
      }}
    >
      <strong>Images:</strong>
      &nbsp;
      {!imagesLoaded && (
        <button className="btn btn-primary btn-sm" onClick={() => loadImages()}>
          Load images
          {showLoading && (
            <>
              &nbsp;&nbsp;
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          )}
        </button>
      )}
      {imagesLoaded && images.length > 0 && (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setShowImages(!showImages)}
        >
          {showImages ? "Hide" : "Show"} images
        </button>
      )}
      {imagesLoaded && images.length === 0 && (
        <>
          <span>No images found for this update </span>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => scrapeImages()}
          >
            Retry image scraping
          </button>
        </>
      )}
      {showImages && images.length > 0 && (
        <div
          style={{
            overflowY: "scroll",
            overflowX: "visible",
            height: leftColumnRef.current?.clientHeight - 32,
            marginTop: "1rem",
          }}
        >
          {images.map((image) => (
            <img
              src={image}
              alt={image}
              key={image}
              onDoubleClick={() => deleteImage(image)}
              style={{
                width: "100%",
                marginBottom: "1rem",
                border: "2px solid #ccc",
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
