// @ts-check

const config = {
  UpdatesDatabaseEndpoint: "/api/get-updates-cosmos",
  CheckUpdateEndpoint: "/api/mark-update-as-checked-cosmos",
  GetImagesEndpoint: "/api/get-update-images-cosmos",
  DeleteImageEndpoint: "/api/delete-update-image-cosmos",
  ScrapeImagesEndpoint: "/api/scrape-images",
  GenerateSpeakerNotesEndpoint: "/api/generate-speaker-notes",
  UpdatesScraperEndpoint:
    "https://technical-update-briefing.azurewebsites.net/api/v1/NewUpdate?code=CBERYCgaN2udNHBR526FLHDE0e99iWDcNHQwxBNkKC/nXz4SuxSNuw==",
  statusColors: {
    "IN PREVIEW": "#50e6ff",
    "NOW AVAILABLE": "#0078d4",
    "IN DEVELOPMENT": "#737373",
    "PRIVATE PREVIEW": "#d83b01",
  },
};

module.exports = config;
