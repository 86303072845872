import React from "react";
export default function Footer({
  amountUpdates,
  loadTime,
  onlyNew,
  daysAgo,
  amountServices,
  errors,
  setErrors,
}) {
  const bg = onlyNew ? "bg-success" : "bg-danger";
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Errors
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {errors.map((error) => (
                <div key={error}>
                  <p>{error}</p>
                  <hr />
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => setErrors([])}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "bg-dark position-fixed w-100 bottom-0 start-0 end-0 d-flex justify-content-end"
        }
        style={{ height: 24, color: "white", zIndex: 10 }}
      >
        <div
          className="ps-2 pe-2 bg-danger cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-bug-fill mb-1"
            viewBox="0 0 16 16"
          >
            <path d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z" />
            <path d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z" />
          </svg>{" "}
          {errors.length}
        </div>
        <div className="ps-2 pe-2 bg-primary">{amountUpdates} updates</div>
        <div className="ps-2 pe-2">{amountServices} services</div>

        <div className="ps-2 pe-2">{loadTime} ms</div>
        <div className={"ps-2 pe-2 " + bg}>
          {onlyNew ? "Only new" : "All"} updates from past {daysAgo} days
        </div>
      </div>
    </>
  );
}
