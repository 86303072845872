import React from 'react';
import CopyableText from "../CopyableText";
import moment from "moment";
import axios from "axios";
import config from "../config";
import { useState, useRef } from "react";
import ImagesList from "./ImagesList";

export default function Update({ update, triggerReload = () => {}, setError }) {
  const { CheckUpdateEndpoint, statusColors } = config;

  const [copied, setCopied] = useState({
    service: false,
    title: false,
    date: false,
  });

  const [loading, setLoading] = useState(false);

  const leftColumnRef = useRef(null);

  const allCopied = () => copied.service && copied.title && copied.date;

  const checkAllRequiredItemsCopied = async (e) => {
    setLoading(true);
    if (e.target.checked && !allCopied()) {
      const r = window.confirm(
        "You did not copy all the usual fields (service, title and date). Do you want to proceed?"
      );
      if (r === false) {
        setLoading(false);
        return;
      }
    }
    try {
      await axios.post(CheckUpdateEndpoint, {
        id: update.id,
        checked: e.target.checked,
      });
    } catch (e) {
      setLoading(false);
      const errorMessage = `Error marking update as checked on ${CheckUpdateEndpoint}: ${e.message}`;
      setError(errorMessage);
      console.log(e);
    }
    triggerReload();
    setLoading(false);
  };

  return (
    <div className="col-12">
      <div className="card" style={{ marginTop: 8 }}>
        <h5 className={`card-header ${update.checked && "bg-checked"}`}>
          {loading ? (
            "🔃"
          ) : (
            <input
              type="checkbox"
              onChange={checkAllRequiredItemsCopied}
              checked={update.checked}
            />
          )}
          &nbsp;
          {moment(update.date).format("D MMMM YYYY")} - {update.title}
        </h5>
        <div className="card-body">
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 8,
              height: "100%",
              backgroundColor: statusColors[update.status] || "transparent",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          />
          <div className="row">
            <div
              className="col-12 col-lg-8 col-md-6"
              style={{ borderRight: "1px solid #eee" }}
              ref={leftColumnRef}
            >
              <div style={{ width: "100%", overflowX: "scroll" }}>
                <p>
                  <strong>Service:</strong>{" "}
                  <CopyableText
                    text={update.service}
                    onCopy={() => setCopied({ ...copied, service: true })}
                  />
                </p>
                <p>
                  <strong>Title:</strong>{" "}
                  <CopyableText
                    text={update.title}
                    onCopy={() => setCopied({ ...copied, title: true })}
                  />
                </p>
                <p>
                  <strong>Month:</strong>{" "}
                  <CopyableText
                    text={moment(update.date).format("MMMM YYYY")}
                    onCopy={() => setCopied({ ...copied, date: true })}
                  />
                </p>
                <p>
                  <strong>Status:</strong> <CopyableText text={update.status} />
                </p>
                <p>
                  <strong>Link:</strong>{" "}
                  <CopyableText text={update.link} isLink />
                </p>
                <strong>Description:</strong>
                <CopyableText text={update.detailsHTML} isHTML />
              </div>
            </div>
            <ImagesList leftColumnRef={leftColumnRef} update={update} setError={setError} />
          </div>
        </div>
      </div>
    </div>
  );
}
