import React from "react";
import AzureLogo from "../../assets/azure.png";
export default function NavBar({ user, oldUI, setOldUI }) {
  return (
    <nav
      className="navbar navbar-dark bg-dark"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <div className="container-fluid">
        <span className="navbar-brand mb-0 h1">
          <img
            src={AzureLogo}
            alt="Azure"
            style={{
              height: "1.3em",
              width: "auto",
              marginRight: "0.5em",
            }}
          />
          Technical Update Briefing
        </span>
        <div className={"col-auto"}>
          <div className="form-check form-switch mt-2">
            <label
              className="form-check-label"
              htmlFor={"old-ui"}
              style={{ cursor: "pointer", color: "white" }}
            >
              Use old UI
            </label>

            <input
              className="form-check-input"
              type={"checkbox"}
              role={"switch"}
              checked={oldUI}
              onChange={(e) => setOldUI(e.target.checked)}
              id={"old-ui"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="d-flex">
          {user ? (
            <div>
              <span className="navbar-text" title={user.userId}>
                Welcome <strong>{user.userDetails}</strong> &nbsp;
                {user.userRoles.includes("admin") && (
                  <span className="badge bg-danger">Admin</span>
                )}
              </span>
              &nbsp; &nbsp;
              <a className="btn btn-outline-success" href={"/logout"}>
                Logout
              </a>
            </div>
          ) : (
            <a className="btn btn-outline-success" href={"/login"}>
              Login
            </a>
          )}
        </div>
      </div>
    </nav>
  );
}
