import React from 'react'
import CopyIcon from "../assets/copy.png";
import CheckedIcon from "../assets/checked.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useState, useRef } from "react";
export default function CopyableText({
  text,
  onCopy = () => {},
  isHTML = false,
  isLink = false,
}) {
  const [copied, setCopied] = useState(false);
  const htmlContent = useRef();
  if (isHTML)
    return (
      <div>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            onCopy();
            setCopied(true);
          }}
        >
          <img
            src={copied ? CheckedIcon : CopyIcon}
            style={{ height: 16, width: 16 }}
            alt="copy"
          />
        </CopyToClipboard>
        <div dangerouslySetInnerHTML={{ __html: text }} ref={htmlContent}></div>
      </div>
    );
  if (isLink)
    return (
      <span>
        <a href={text} target={"_blank"} rel="noreferrer">
          {text}
        </a>{" "}
        <CopyToClipboard
          text={text}
          onCopy={() => {
            onCopy();
            setCopied(true);
          }}
        >
          <img
            src={copied ? CheckedIcon : CopyIcon}
            style={{ height: 16, width: 16 }}
            alt="copy"
          />
        </CopyToClipboard>
      </span>
    );
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        onCopy();
        setCopied(true);
      }}
      style={{ cursor: "pointer" }}
      options={{ format: "text/plain" }}
    >
      <span>
        {text}{" "}
        <img
          src={copied ? CheckedIcon : CopyIcon}
          style={{ height: 16, width: 16 }}
          alt="copy"
        />
      </span>
    </CopyToClipboard>
  );
}
